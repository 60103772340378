// Indicates if podcast episodes are being loaded
function isLoadingEpisodes(state = false, action) {
    let newState;

    switch (action.type) {
        case 'startLoadingEpisodes':
            newState = true;
            break;

        case 'finishLoadingEpisodes':
            newState = false;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default isLoadingEpisodes;
