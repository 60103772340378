import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import routes from '../../utils/routes';

import styles from './styles.module.scss';

const SLUG_REGEX = /^[a-f0-9-]+$/;

const propTypes = {
    match: object,
};

const defaultProps = {
    match: null,
};

function Player({ match }) {
    const [episodeId, setEpisodeId] = useState(match && match.params.episodeId);

    useEffect(() => {
        if (match && match.params.episodeId && match.params.episodeId !== episodeId) {
            setEpisodeId(match.params.episodeId);
        }
    }, [match]);

    if (!match && !episodeId) {
        return null;
    }

    if (!SLUG_REGEX.test(episodeId)) {
        return null;
    }

    const playerClasses = [styles.playerModal];
    if (match) {
        playerClasses.push(styles.full);
    }

    return (
        <div className={playerClasses.join(' ')}>
            <iframe
                className={styles.player}
                title="HyperConnected Player"
                frameBorder="no"
                scrolling="no"
                seamless
                src={`https://player.simplecast.com/${episodeId}?dark=false`}
            />
            <Link
                to={routes.home()}
                className={styles.closeModal}
            />
            <button
                type="button"
                className={styles.closePlayer}
                onClick={() => setEpisodeId(null)}
            />
        </div>
    );
}

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default Player;
