import React from 'react';
import { Link } from 'react-router-dom';
import { string, number } from 'prop-types';
import zeroPad from '../../utils/zeroPad';
import routes from '../../utils/routes';
import apple from './apple.svg';
import google from './google.svg';
import spotify from './spotify.svg';

import styles from './styles.module.scss';

const propTypes = {
    episode: number.isRequired,
    title: string.isRequired,
    image: string.isRequired,
    description: string.isRequired,
    audio: string.isRequired,
    episodeId: string.isRequired,
}

function Podcast({
    episode,
    title,
    image,
    description,
    audio,
    episodeId,
}) {
    const imageStyle = { backgroundImage: `url(${image})` };

    return (
        <div className={styles.podcast}>
            <div className={styles.title}>
                {`EPISÓDIO - ${zeroPad(episode)}. ${title}`}
            </div>
            <div className={styles.description}>
                {description}
            </div>
            <div className={styles.spacer} />
            <Link
                to={routes.player(episodeId)}
                className={styles.podcastContainer}
                style={imageStyle}
            >
                <div className={styles.podcastContent}>
                    {title}
                </div>
            </Link>
            <div className={styles.buttonsContainer}>
                <div className={styles.socialButtons}>
                    <a href="https://open.spotify.com/show/4qO6KjI4dvuAIKkwyCphok" target="_blank" rel="noopener noreferrer">
                        <img src={spotify} alt={'Spotify'} />
                    </a>
                    <a href="https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS9fUlhMbk02aA" target="_blank" rel="noopener noreferrer">
                        <img src={google} alt={'Google Podcasts'} />
                    </a>
                    <a href="https://podcasts.apple.com/br/podcast/hyper-connected/id1478020624?l=en" target="_blank" rel="noopener noreferrer">
                        <img src={apple} alt={'Apple Podcasts'} />
                    </a>
                </div>
                <a href={routes.download(new URL(audio).pathname)} download className={styles.downloadButton}>
                    Download
                </a>
            </div>
        </div>
    );
}

Podcast.propTypes = propTypes;

export default Podcast;
