// Stores the number of the last page of loaded episodes
function currentPage(state = 0, action) {
    let newState;

    switch (action.type) {
        case 'setCurrentPage':
            newState = action.page;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default currentPage;
