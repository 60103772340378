import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';

function storeFactory(initialState = undefined) {
    return createStore(
        combineReducers(reducers),
        initialState,
        composeWithDevTools(),
    );
}

export default storeFactory;
