import React from 'react';
import Header from '../../components/Header';
import Playlist from '../../components/Playlist';
import WorkCard from '../../components/WorkCard';
import Footer from '../../components/Footer';

function Home() {
    return [
        <Header key="header" />,
        <Playlist key="playlist" />,
        <WorkCard
            key="workcard"
            photos={[
                '/images/projects2.jpg',
                '/images/projects1.jpg',
            ]}
            href="https://dojo.do/projetos"
            position="bl"
            bright
        >
            Nossos
            <br />
            Trabalhos
        </WorkCard>,
        <Footer key="footer" />,
    ];
}

export default Home;
