// A default animated loading spinner
import React from 'react';
import { number, string } from 'prop-types';

const propTypes = {
    size: number,
    color: string,
    className: string,
    id: string,
};

const defaultProps = {
    size: 100,
    color: '#000000',
    className: null,
    id: null,
};

const LoadingSpinner = ({ size, color, className, id }) => {
    const params = {};
    if (id) {
        params.id = id;
    }

    return (
        <svg
            className={className}
            width={size}
            height={size}
            viewBox="0 0 100 100"
            { ...params }
        >
            <circle
                width={`${size}px`}
                height={`${size}px`}
                cx="50px"
                cy="50px"
                fill="none"
                stroke={color}
                strokeWidth="10px"
                r="35px"
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    );
};

LoadingSpinner.propTypes = propTypes;
LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
