import React from 'react';
import scrollTo from '../../utils/scrollTo';

import styles from './styles.module.scss';

const Header = () => (
    <div className={styles.header}>
        <div className={styles.content}>
            <div className={styles.title}>
                HyperConnected
            </div>
            <div className={styles.subtitle}>
                by DOJO.
            </div>
            <div className={styles.lead}>
                Em 2020, 50 bilhões de coisas estarão conectadas à Internet. 
                <br />
                E nós temos 50 bilhões de coisas para falar sobre isso. 
            </div>
            <div className={styles.cta} onClick={() => scrollTo('#podcasts')}>
                Ouça
                {' '}
                <span className={styles.underline}>
                    agora.
                </span>
            </div>
        </div>
    </div>
);

export default Header;
