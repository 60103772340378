import isLoadingEpisodes from './isLoadingEpisodes';
import episodes from './episodes';
import currentPage from './currentPage';
import pages from './pages';

export default {
    isLoadingEpisodes,
    episodes,
    currentPage,
    pages,
};
