import { useSelector, useDispatch } from 'react-redux';
import PodcastService from '../services/PodcastService';

function useLoadEpisodes() {
    const currentPage = useSelector(({ currentPage }) => currentPage);
    const pages = useSelector(({ pages }) => pages);
    const isLoadingEpisodes = useSelector(({ isLoadingEpisodes }) => isLoadingEpisodes);
    const dispatch = useDispatch();

    function loadEpisodes() {
        if (isLoadingEpisodes || currentPage >= pages) {
            return;
        }

        dispatch({ type: 'startLoadingEpisodes' });
        return PodcastService.loadPodcasts(currentPage + 1).then(result => {
            dispatch({ type: 'addEpisodes', episodes: result.episodes || [] });
            dispatch({ type: 'setCurrentPage', page: result.page });
            dispatch({ type: 'setPages', pages: result.pages });
            dispatch({ type: 'finishLoadingEpisodes' });

            return result;
        });
    }

    return [loadEpisodes, currentPage >= pages];
}

export default useLoadEpisodes;
