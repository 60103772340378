import React from 'react';
import { string } from 'prop-types';

import styles from './styles.module.scss';

const propTypes = {
    className: string,
};

const defaultProps = {
    className: null,
};

const Ideogram = ({ className }) => <a href="https://dojo.do" target="_blank" className={[styles.ideogram, className].join(' ')} />;

Ideogram.propTypes = propTypes;
Ideogram.defaultProps = defaultProps;

export default Ideogram;
