import React from 'react';
import { string, bool, arrayOf, oneOf, node } from 'prop-types';

import styles from './styles.module.scss';

const propTypes = {
    children: node.isRequired,
    photos: arrayOf(string).isRequired,
    href: string.isRequired,
    bright: bool,
    position: oneOf(['tl', 'tr', 'bl', 'br']).isRequired,
    className: string,
};

const defaultProps = {
    className: null,
    bright: false,
};

function WorkCard({
    children,
    photos,
    href,
    bright,
    position,
    className,
}) {
    const classes = [styles.workCard, className];
    if (bright) {
        classes.push(styles.bright);
    }
    classes.push(styles[position]);

    const photoElements = photos.map(photoUrl => (
        <div
            className={styles.image}
            style={{ backgroundImage: `url(${photoUrl})` }}
            key={photoUrl}
        />
    ));

    return (
        <div className={classes.join(' ')}>
            <div className={styles.content}>
                <a
                    className={styles.workLink}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={styles.workLabel}>
                        Dojo
                    </div>
                    <div className={styles.title}>
                        {children}
                    </div>
                </a>
            </div>
            {photoElements}
        </div>
    );
}

WorkCard.propTypes = propTypes;
WorkCard.defaultProps = defaultProps;

export default WorkCard;
