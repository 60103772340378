import TweenLite, { Power1 } from 'gsap/umd/TweenLite';
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin';

const gsapPlugins = [ScrollToPlugin];

function scrollTo(target, onComplete = null) {
    return new Promise((resolve, reject) => {
        TweenLite.to('#app', 0.5, {
            scrollTo: target,
            onComplete: () => {
                if (onComplete) {
                    onComplete();
                }
                resolve();
            },
            ease: Power1.easeOut,
        });
    });
}

export default scrollTo;
