// Stores the number of pages that can be loaded from the server
function pages(state = 1, action) {
    let newState;

    switch (action.type) {
        case 'setPages':
            newState = action.pages;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default pages;
