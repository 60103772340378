import fetch from 'isomorphic-unfetch';

const PodcastService = {
    doGETRequest(endpoint, parameters = {}) {
        const endpointURL = new URL(`https://api.simplecast.com${endpoint}`);
        Object.keys(parameters).forEach(key => endpointURL.searchParams.set(key, parameters[key]));

        return fetch(endpointURL.toString())
            .then((res) => {
                if (res.status >= 400) {
                    return res.json().then((responseBody) => {
                        throw new Error(responseBody.message);
                    });
                }

                return res.json();
            })
            .catch((error) => {
                console.log('Fetch error', error);
                return { error };
            });
    },

    loadPodcasts(page = 1) {
        const parameters = {
            limit: process.env.REACT_APP_EPISODES_PER_PAGE,
            offset: (page - 1) * process.env.REACT_APP_EPISODES_PER_PAGE,
            status: 'published',
        };

        return this.doGETRequest(`/podcasts/${process.env.REACT_APP_PODCAST_ID}/episodes`, parameters).then(result => {
            const episodes = result.collection.map(episodeData => ({
                episode: episodeData.number,
                title: episodeData.title,
                image: episodeData.image_url,
                description: episodeData.description,
                audio: episodeData.enclosure_url,
                episodeId: episodeData.id,
            }));

            return {
                episodes,
                page,
                pages: result.pages.total,
            };
        });
    },

    isDojoEpisode(episodeId) {
        return this.doGETRequest(`/episodes/${episodeId}`).then(
            result => result.podcast && result.podcast.id === process.env.REACT_APP_PODCAST_ID,
        );
    },
};

export default PodcastService;
