import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../LoadingSpinner';
import Podcast from '../Podcast';
import useLoadEpisodes from '../../hooks/useLoadEpisodes';

import styles from './styles.module.scss';

function Playlist() {
    const episodes = useSelector((state) => state.episodes);
    const isLoadingEpisodes = useSelector((state) => state.isLoadingEpisodes);
    const [loadEpisodes, loadedAllEpisodes] = useLoadEpisodes();

    useEffect(() => {
        if (!episodes.length) {
            loadEpisodes();
        }
    }, []);

    let loadingElement = null;
    if (isLoadingEpisodes) {
        loadingElement = <LoadingSpinner className={styles.loading} />;
    } else if (!loadedAllEpisodes) {
        loadingElement = (
            <button onClick={loadEpisodes}>
                Carregar mais episódios
            </button>
        );
    }

    const podcastNodes = episodes.map((podcast, i) => (
        <Podcast
            key={podcast.episode}
            episode={podcast.episode}
            title={podcast.title}
            image={podcast.image}
            description={podcast.description}
            audio={podcast.audio}
            episodeId={podcast.episodeId}
        />
    ));

    return (
        <div className={styles.playlist}>
            <div className={styles.playlistGrid} id="podcasts">
                {podcastNodes}
            </div>
            {loadingElement}
        </div>
    );
}

export default Playlist;
