import React from 'react';
import { Route } from 'react-router-dom';
import gtmParts from 'react-google-tag-manager';
import Home from './views/Home';
import Player from './views/Player';
import Ideogram from './components/Ideogram';
import routes from './utils/routes';

import styles from './App.module.scss';

function App() {
    const gtm = gtmParts({
        id: 'GTM-NZJSXNJ',
    });

    return (
        <div className={styles.app} id="app">
            <Route path={routes.home()} component={Home} />
            <Route
                path={routes.player(':episodeId')}
                children={({ match }) => <Player match={match} /> } />
            <Ideogram />
            {gtm.noScriptAsReact()}
            {gtm.scriptAsReact()}
        </div>
    );
}

export default App;
