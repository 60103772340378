// Stores the list of loaded episodes
import deepClone from '../../utils/deepClone';

function episodes(state = [], action) {
    let newState;

    switch (action.type) {
        case 'setEpisodes':
            newState = deepClone(action.episodes);
            break;

        case 'addEpisodes':
            newState = deepClone(state.concat(action.episodes));
            break;

        default:
            newState = state;
    }

    return newState;
}

export default episodes;
